import React from 'react';
import Style from './Home.module.scss';
import me from '../../img/self.png';
import CCcloud from '../../img/CCcloud.png'
import classNames from 'classnames';
import EmojiBullet from "./EmojiBullet";
import SocialIcon from "./SocialIcon";
import {Box, TextField, Button} from "@mui/material";
import {info} from "../../info/Info";
import emailjs from 'emailjs-com';

export default function Home() {
  const [form, setForm] = React.useState({
    name: "",
    email: "",
    message: "",
  });

  const [status, setStatus] = React.useState(null);

  const handleInputChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    emailjs.send(
      'service_hs8plil', 
      'template_hfme05n', 
      form, 
      'vhdvTpQCHDanns4mb' // replace 'user_id' with your actual user_id
    )
    .then((result) => {
      console.log(result.text);
      setStatus("Email sent successfully, you will should hear back from us shortly");
    }, (error) => {
      console.log(error.text);
      setStatus("Error occurred while sending Email");
    });
  
    setForm({
      name: "",
      email: "",
      message: "",
    });
  };
  
  return (
    <Box 
      component={'main'} 
      display={'flex'} 
      flexDirection={{xs: 'column', md: 'row'}} 
      alignItems={{xs: 'center', md: 'flex-start'}}
      justifyContent={'center'} 
      minHeight={'calc(100vh - 175px)'} 
      marginTop={'2rem'}
    >
      <Box 
        className={classNames(Style.avatar, Style.shadowed)} 
        alt={'image of company'}  
        component={'img'} 
        src={CCcloud} 
        width={{xs: '40vh', md: '40vh'}}
        height={{xs: '40vh', md: '40vh'}}
        borderRadius={'25%'} 
        p={'0.75rem'} 
        mb={{xs: '1rem', sm: 0}} 
        mr={{md: '2rem'}}
      />


         
         <Box>
            <h1>Hi, We are <span style={{background: info.gradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>{info.firstName}</span><span className={Style.hand}>🤚</span>
            </h1>
            <h2> {info.position}.</h2>
            <Box padding={'5px'}>
               <h3 style={{ fontSize: "1.5em", color: "#333", marginTop:"1rem" }}>
                  We specialize in services that will move your business forward
               </h3>
            </Box>

            <Box padding={'5px'} component={'ul'} p={'0.8rem'}>
               {info.miniBio.map((bio, index) => (
                  <EmojiBullet key={index} emoji={bio.emoji} text={bio.text}/>
               ))}
            </Box>

            {/* Contact Form */}
            {status === null && (
              <Box  padding={'5px'} component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
                <TextField 
                  name="name"
                  label="Name" 
                  variant="outlined"
                  value={form.name}
                  onChange={handleInputChange} 
                  fullWidth
                  required
                />
                <TextField 
                  name="email"
                  label="Email" 
                  variant="outlined"
                  value={form.email}
                  onChange={handleInputChange} 
                  fullWidth
                  required
                />
                <TextField
                  name="message"
                  label="Message"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={form.message}
                  onChange={handleInputChange} 
                  fullWidth
                  required
                />
                <Button variant="contained" color="primary" type="submit">
                  Send
                </Button>
              </Box>
            )}
            {/* Status message */}
            {status && (
              <Box display="flex" justifyContent="center" marginBottom={'2rem'} marginTop={'2rem'}>
  <p style={{ fontSize: '1.5em', color: 'grey' }}>{status}</p>
</Box>

            )}

            <Box display={'flex'} gap={'1.5rem'} justifyContent={'center'} fontSize={{xs: '2rem', md: '2.5rem'}}>
               {info.socials.map((social, index) => (
                  <SocialIcon key={index} link={social.link} icon={social.icon} label={social.label} />
               ))}
            </Box>
         </Box>
      </Box>
   )
}
