import mock1 from "../img/mock1.png"
import mock2 from "../img/mock2.png"
import mock3 from "../img/mock3.png"
import mock4 from "../img/mock4.png"
import mock5 from "../img/mock5.png"
import CCcloud from "../img/CCcloud.png"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */


/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    firstName: "Clover Lab",
    lastName: "",
    initials: "Clover Lab", // the example uses first and last, but feel free to use three or more if you like.
    position: "Business Analytics and Efficiency Solutions",
    selfPortrait: CCcloud, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '💻',
            text: 'Modern Web App Development'
        },
        {
            emoji: '🔐',
            text: 'Technology Security'
        },
        {
            emoji: "💼",
            text: "Project Management"
        },
        {
            emoji: "🔗",
            text: "System Integration and Process Automation"
        },
        {
            emoji: "💡",
            text: "Business Apps Consulting"
        },
        {
            emoji: "☁️",
            text: "Cloud Solutions and Hosting"
        },
        {
            emoji: "📂",
            text: "Regulatory Compliance"
        },

    ],
    socials: [

        {
            link: "https://www.linkedin.com/company/18470956",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        },



    ],
    bio: "We are a leading-edge provider of business technology consulting, specializing in business analytics, Microsoft 365 applications, custom app development, and process optimization. We don't just work with businesses, we also partner with IT providers to augment their existing services. Our mission is to help businesses and IT providers unlock their full potential by leveraging technology to drive efficiency, productivity, and growth.",
    bioSkills: "Our team's skills extend to effective business analysis, project management, and cybersecurity measures, ensuring streamlined, secure, and efficient operations for (SMBs). Furthermore, we are proficient in leveraging M365 and gSuite business applications, providing businesses with seamless communication, collaboration, and productivity tools.",
    AISkills: "Clover Lab's AI proficiency is beyond compare. We work with AI technologies to facilitate intelligent integration, enhancing efficiency and providing a competitive edge for our clients.",

    portfolio: [ // This is where your portfolio projects will be detailed
       
    ]
}